var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"sm","title":"Duyệt giấy phép","centered":"","hide-footer":true}},[_c('div',{staticClass:"modal-accept"},[_c('validation-observer',{ref:"rule"},[_c('b-form',{staticClass:"content-form"},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Số quyết định"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":_vm.customCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"InputHelp","state":errors.length > 0 ? false:null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Mã giấy phép "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":_vm.customCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"InputHelp","state":errors.length > 0 ? false:null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Ngày hiệu lực "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"startDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{class:{'is-invalid':errors.length > 0 },attrs:{"disabledInput":true}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Ngày hết hiệu lực "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"startDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{class:{'is-invalid':errors.length > 0 },attrs:{"disabledInput":true}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Phản hồi")]),_c('b-form-textarea',{attrs:{"id":"InputHelp","rows":"3","no-resize":""}})],1),_c('div',{staticClass:"action"},[(!_vm.isNotification)?_c('b-button',{staticClass:"mr-1 btn-left",attrs:{"variant":"outline-danger"},on:{"click":_vm.cancel}},[_vm._v(" Hủy bỏ ")]):_vm._e(),_c('b-button',{staticClass:"mr-1 btn-left",attrs:{"variant":"danger"}},[_vm._v(" Từ chối ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.accept}},[_vm._v(" Đồng ý ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }