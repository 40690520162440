export default {
  dataStatus: [
    {
      statusLicenseAccrediation: 'NonLiscening',
      statusLicenseAccrediationString: 'Chưa cấp phép',
    },
    {
      statusLicenseAccrediation: 'PendingLicensing',
      statusLicenseAccrediationString: 'Đang chờ duyệt cấp phép',
    },
    {
      statusLicenseAccrediation: 'Licensing',
      statusLicenseAccrediationString: 'Duyệt cấp phép',
    },
    {
      statusLicenseAccrediation: 'RejectLicense',
      statusLicenseAccrediationString: 'Từ chối cấp phép',
    },
    {
      statusLicenseAccrediation: 'PendingRenew',
      statusLicenseAccrediationString: 'Đang chờ duyệt cấp lại',
    },
    {
      statusLicenseAccrediation: 'Renew',
      statusLicenseAccrediationString: 'Đã cấp lại',
    },
    {
      statusLicenseAccrediation: 'PendingExtend',
      statusLicenseAccrediationString: 'Đang chờ duyệt gia hạn',
    },
    {
      statusLicenseAccrediation: 'Extend',
      statusLicenseAccrediationString: 'Đã gia hạn',
    },
    {
      statusLicenseAccrediation: 'Revoke',
      statusLicenseAccrediationString: 'Thu hồi giấy phép',
    },
  ],
}
