<template>
  <b-modal
    :id="id"
    size="sm"
    title="Duyệt giấy phép"
    centered
    :hide-footer="true"
  >
    <div class="modal-accept">
      <validation-observer ref="rule">
        <b-form class="content-form">
          <b-form-group>
            <label for="InputHelp">Số quyết định<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="customCode"
            >
              <b-form-input
                id="InputHelp"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="InputHelp">Mã giấy phép <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="customCode"
            >
              <b-form-input
                id="InputHelp"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label>Ngày hiệu lực <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="startDate"
              rules="required"
            >
              <date-time-picker
                :disabledInput="true"
                :class="{'is-invalid':errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label>Ngày hết hiệu lực <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="startDate"
              rules="required"
            >
              <date-time-picker
                :disabledInput="true"
                :class="{'is-invalid':errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>

      <b-form-group>
        <label for="InputHelp">Phản hồi</label>
        <b-form-textarea
          id="InputHelp"
          rows="3"
          no-resize
        />
      </b-form-group>

      <div class="action">
        <b-button
          v-if="!isNotification"
          variant="outline-danger"
          class="mr-1 btn-left"
          @click="cancel"
        >
          <!-- {{ $t("common.ok-title") }} -->
          Hủy bỏ
        </b-button>
        <b-button
          class="mr-1 btn-left"
          variant="danger"
        >
          <!-- {{ $t("common.cancel-title") }} -->
          Từ chối
        </b-button>
        <b-button
          variant="primary"
          @click="accept"
        >
          <!-- {{ $t("common.cancel-title") }} -->
          Đồng ý
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    isNotification: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // queryBody: {
      //   level1: null,
      //   level2: null,
      //   level3: null,
      //   level4: null,
      //   level5: null,
      //   name: null,
      //   parentId: null,
      //   description: null,
      // },
      customCode: {
        required: 'Tên danh mục nghề nghiệp là bắt buộc',
      },
    }
  },
  methods: {
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      // this.$emit('accept', this.parentId, this.$refs.rule)
    },
    cancel() {
      this.$bvModal.hide(this.id)
    },
  },
}
</script>

<style lang="scss">
.modal-accept {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>
